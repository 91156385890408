import React from 'react'
import { Box } from '@chakra-ui/react';

const Settings = () => {
    return (
        <Box padding={5}>
            Placeholder page for Settings.
        </Box>
    )
}

export default Settings